/**
* @param date - Date to be formatted, need not be JS date, ISO string would be fine as well
* @param isTimeRequired - If set to true, will also return the time of the day component
*/
// import http from 'fetch-bb';
import CONSTANTS from '../lib/constants';
import { AppStore } from '../lib/store';
import moment from "moment";
import ContextOne from '../components/context';
import { useContext } from "preact/hooks";

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function monthName(month) {
  return months[month] ? months[month] : '';
}

/*
  BY: Prasannadatta Kawadkar
  ON: 14 March 2024
  Modification: added function to get month number and percentage and truncate string and time format
*/
function getMonthNumber(monthName) {
  let response = months.indexOf(monthName) + 1;
  return response.toString().padStart(2, '0');
}

function calculatePercentage(numerator, denominator) {
    if (Number(denominator) === 0) {
        return null; //
    }
    const percentage = (Number(numerator) / Number(denominator)) * 100;
    return `${Math.round(percentage)}%`; // Return percentage rounded to two decimal places
}

function truncateString(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  } else {
    return str;
  }
}

function formatDateWithoutTime(date) {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString(undefined, options);
}
/*
  By: Yashvi
  On: 9th July 2024
  T1914: The timestamp is wrong in the chat when the message is sent by the System-Bot
  Modification: If time is already in 12 hour format return time else change the format
*/
function formatTimeIn12HourFormat(timeString) {
  // Check if the input time already contains AM/PM
  let is12HourFormat = timeString.toUpperCase().includes("AM") || timeString.toUpperCase().includes("PM");

  if (is12HourFormat) {
    // If the input time is already in 12-hour format with seconds, remove the seconds
    let timeParts = timeString.split(":");
    let period = timeParts[2].slice(3); // Extract the AM/PM period
    return `${timeParts[0]}:${timeParts[1]} ${period}`;
  } else {
    // If the input time is in 24-hour format
    let splitTimeString = timeString.split(":");
    let hours = parseInt(splitTimeString[0]);
    let minutes = splitTimeString[1];
    let period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format
    return `${hours}:${minutes} ${period}`;
  }
}

function formatDateTime(date, isTimeRequired) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone:'Asia/Kolkata'
  };
  let formattedDate = '';
  if (isTimeRequired) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.second = 'numeric';
  }
  if (date) {
    formattedDate = new Intl.DateTimeFormat('en-IN', options).format(new Date(date));
  }
  return formattedDate;
}

function formatDateTimeForDashboard(date, isTimeRequired) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone:'Asia/Kolkata'
  };
  let formattedDate = '';
  if (isTimeRequired) {
    options.hour = 'numeric';
    options.minute = 'numeric';
    options.second = 'numeric';
  }
  if (date) {
    formattedDate = new Intl.DateTimeFormat('en-IN', options).format(new Date(date).setHours(new Date(date).getHours()-5,new Date(date).getMinutes()-30,0));
  }
  return formattedDate;
}

function formatTime(timeString) {
  let splitTimeString = timeString.split(":");
  return `${splitTimeString[0]}:${splitTimeString[1]}`;
};

function getFormattedAmount(amount) {
  if (!isNaN(amount) || amount === 0) {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount).split('.')[0];
  }
  return '-';
}

function applyAclForFeedAndChat(actorID,loggedInID) {
  if (actorID === loggedInID){
    return true;
  }
  return false;
}

function startLoader() {
  let element = document.getElementById('loader-bg');
  element.style.display = 'block';
}

function stopLoader() {
  let element = document.getElementById('loader-bg');
  element.style.display = 'none';
}

function getCommaSeparatedValues(list, value) {
  let names = [];
  if (!value) value = 'displayName';
  list.map((row) => {
    row ? names.push(row[value]) : '';
  });
  names = names.join(', ');
  return names;
}

function amountInWordsConversion(amount) {
  let mapping = {
    '0': '',
    '1': 'एक',
    '2': 'दोन',
    '3': 'तीन',
    '4': 'चार',
    '5': 'पाच',
    '6': 'सहा',
    '7': 'सात',
    '8': 'आठ',
    '9': 'नऊ',
    '10': 'दहा',
    '11': 'अकरा',
    '12': 'बारा',
    '13': 'तेरा',
    '14': 'चौदा',
    '15': 'पंधरा',
    '16': 'सोळा',
    '17': 'सतरा',
    '18': 'अठरा',
    '19': 'एकोणीस',
    '20': 'वीस',
    '21': 'एकवीस',
    '22': 'बावीस',
    '23': 'तेवीस',
    '24': 'चोवीस',
    '25': 'पंचवीस',
    '26': 'सव्वीस',
    '27': 'सत्तावीस',
    '28': 'अठ्ठावीस',
    '29': 'एकोणतीस',
    '30': 'तीस',
    '31': 'एकतीस',
    '32': 'बत्तीस',
    '33': 'तेहेतीस',
    '34': 'चौतीस',
    '35': 'पस्तीस',
    '36': 'छत्तीस',
    '37': 'सदतीस',
    '38': 'अडतीस',
    '39': 'एकोणचाळीस',
    '40': 'चाळीस',
    '41': 'एक्केचाळीस',
    '42': 'बेचाळीस',
    '43': 'त्रेचाळीस',
    '44': 'चव्वेचाळीस',
    '45': 'पंचेचाळीस',
    '46': 'सेहेचाळीस',
    '47': 'सत्तेचाळीस',
    '48': 'अठ्ठेचाळीस',
    '49': 'एकोणपन्नास',
    '50': 'पन्नास',
    '51': 'एक्कावन्न',
    '52': 'बावन्न',
    '53': 'त्रेपन्न',
    '54': 'चोपन्न',
    '55': 'पंचावन्न',
    '56': 'छप्पन्न',
    '57': 'सत्तावन्न',
    '58': 'अठ्ठावन्न',
    '59': 'एकोणसाठ',
    '60': 'साठ',
    '61': 'एकसष्ठ',
    '62': 'बासष्ठ',
    '63': 'त्रेसष्ठ',
    '64': 'चौसष्ठ',
    '65': 'पासष्ठ',
    '66': 'सहासष्ठ',
    '67': 'सदुसष्ठ',
    '68': 'अडुसष्ठ',
    '69': 'एकोणसत्तर',
    '70': 'सत्तर',
    '71': 'एक्काहत्तर',
    '72': 'बाहत्तर',
    '73': 'त्र्याहत्तर',
    '74': 'चौर्‍याहत्तर',
    '75': 'पंच्याहत्तर',
    '76': 'शहात्तर',
    '77': 'सत्याहत्तर',
    '78': 'अठ्ठ्याहत्तर',
    '79': 'एकोण ऐंशी',
    '80': 'ऐंशी',
    '81': 'एक्क्याऐंशी',
    '82': 'ब्याऐंशी',
    '83': 'त्र्याऐंशी',
    '84': 'चौऱ्याऐंशी',
    '85': 'पंच्याऐंशी',
    '86': 'शहाऐंशी',
    '87': 'सत्त्याऐंशी',
    '88': 'अठ्ठ्याऐंशी',
    '89': 'एकोणनव्वद',
    '90': 'नव्वद',
    '91': 'एक्क्याण्णव',
    '92': 'ब्याण्णव',
    '93': 'त्र्याण्णव',
    '94': 'चौऱ्याण्णव',
    '95': 'पंच्याण्णव',
    '96': 'शहाण्णव',
    '97': 'सत्त्याण्णव',
    '98': 'अठ्ठ्याण्णव',
    '99': 'नव्व्याण्णव'
  };
  let amountInWords = '';
  // let amountArray = amount.toString().split('.');
  let splitAmount = amount.toString().split('');
  let length = splitAmount.length;

  if (length === 1) {
    amountInWords = mapping[amount.toString()];
  }

  if (length === 2) {
    amountInWords = mapping[amount.toString()];
  }

  if (length === 3) {
    let array1 = splitAmount.splice(0, 1);

    if (splitAmount[0] === '0') {
      amountInWords = mapping[array1[0]] + 'शे ' + mapping[splitAmount[1]];
    } else {
      amountInWords = mapping[array1[0]] + 'शे ' + mapping[splitAmount.join('')];
    }
  }

  if (length === 4 || length === 5) {
    let array1 = (length === 4) ? splitAmount.splice(0, 1) : splitAmount.splice(0, 2);
    let array2 = splitAmount.splice(0, 1);

    amountInWords = (length === 4) ? (mapping[array1[0]] + ' हजार ') : (mapping[array1.join('')] + ' हजार ');

    if (array2[0] !== '0') {
      amountInWords += mapping[array2[0]] + 'शे ';
    }
    if (splitAmount[0] === '0') {
      amountInWords += mapping[splitAmount[1]];
    } else {
      amountInWords += mapping[splitAmount.join('')];
    }
  }

  if (length === 6 || length === 7) {
    let array1 = (length === 6) ? splitAmount.splice(0, 1) : splitAmount.splice(0, 2);
    let array2 = splitAmount.splice(0, 2);
    let array3 = splitAmount.splice(0, 1);

    amountInWords = (length === 6) ? (mapping[array1[0]] + ' लाख ') : (mapping[array1.join('')] + ' लाख ');

    if (array2[0] === '0' && array2[1] !== '0') {
      amountInWords += mapping[array2[1]] + ' हजार ';
    } else if (array2[0] !== '0'){
      amountInWords += mapping[array2.join('')] + ' हजार ';
    }
    if (array3[0] !== '0') {
      amountInWords += mapping[array3[0]] + 'शे ';
    }
    if (splitAmount[0] === '0') {
      amountInWords += mapping[splitAmount[1]];
    } else {
      amountInWords += mapping[splitAmount.join('')];
    }
  }

  if (length === 8 || length === 9) {
    let array1 = (length === 8) ? splitAmount.splice(0, 1) : splitAmount.splice(0, 2);
    let array2 = splitAmount.splice(0, 2);
    let array3 = splitAmount.splice(0, 2);
    let array4 = splitAmount.splice(0, 1);

    amountInWords = (length === 8) ? (mapping[array1[0]] + ' करोड ') : (mapping[array1.join('')] + ' करोड ');

    if (array2[0] === '0' && array2[1] !== '0') {
      amountInWords += mapping[array2[1]] + ' लाख ';
    } else if (array2[0] !== '0'){
      amountInWords += mapping[array2.join('')] + ' लाख ';
    }
    if (array3[0] === '0' && array3[1] !== '0') {
      amountInWords += mapping[array3[1]] + ' हजार ';
    } else if (array3[0] !== '0'){
      amountInWords += mapping[array3.join('')] + ' हजार ';
    }
    if (array4[0] !== '0') {
      amountInWords += mapping[array4[0]] + 'शे ';
    }
    if (splitAmount[0] === '0') {
      amountInWords += mapping[splitAmount[1]];
    } else {
      amountInWords += mapping[splitAmount.join('')];
    }
  }
  return amountInWords;
}

function convertArrayOfObjectsToCSV(args) {
  function isNumber (n) {
    let t;
    if (n) {t = parseFloat(n.toString().replace(/[^a-zA-Z ]/g, ""));}
    return isFinite(t) && +t === t;
  }
  let result, ctr, keys, columnDelimiter, lineDelimiter, data;
  data = args.data || null;
  if (data === null || !data.length) {
    return null;
  }
  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';
  if (args.columns && args.columns.length) {
    keys = [];
    args.columns.map(column => {
      keys.push(column.field)
    });
  } else {
    const biggestObject = data.reduce((biggest, obj) => {
      if (Object.keys(biggest).length > Object.keys(obj).length) return biggest
      return obj
    });
    keys = Object.keys(biggestObject);
  }
  // keys = Object.keys(data[0]);
  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;
  data.forEach(item => {
    ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      if (item[key]) {
        if (isNumber(item[key]) === true) {
          result += parseFloat((item[key].toString()).replace(/[^a-zA-Z ]/g, ""));
        } else {
          result += "\"" + item[key] + "\"";
        }
      } else {
        result += "\"" + "-" + "\"";
      }
      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
}

function downloadCSV(csvArray, fileName, columns) {
  let link;
  let csv = convertArrayOfObjectsToCSV({
    data: csvArray,
    columns
  });
  if (csv === null)
    return;
  if (!fileName.includes('.csv')) fileName = fileName + '.csv';
  const blob = new Blob([csv], {
    type: "text/csv;charset=utf-8;"
  });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection, Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style = "visibility:hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function stripEmpty(object) {
  Object.keys(object).forEach(key => {
    if (object[key] && typeof object[key] === 'object') stripEmpty(object[key]);
    else if (object[key] === undefined || object[key] === '' || object[key] === null) delete object[key];
  });
}

function removeEmptyObjects(object) {
  Object.keys(object).forEach(key => {
    if (object[key] && typeof object[key] === 'object' && !Object.keys(object[key]).length) {
      delete object[key];
    }
  });
}

//Modified By: Yashvi.
//Modification Date: 8 Dec 2020
//Modification: Changed passwordErrorMessageText function with all the validations.
function passwordErrorMessageText(data, action){
  const regexCaptial = /^(.*?[A-Z])+$/;
  const regexSmall = /^(.*?[a-z])+$/;
  const regexSpCharacter = /^(.*?[#?!@$%^&*-])+$/;
  const regexNumber = /^(.*?[0-9])+$/;
  const regexLength = /^.{6,20}$/;
  const errorMsgForUppercase = ' Uppercase character';
  const errorMsgForLowercase = ' Lowercase character';
  const errorMsgForNumber = ' Number';
  const errorMsgForSpCharacter = ' Symbol';
  const errorMsgForLength = ' and password length should be at least 6 characters long';
  let passwordErrorMessage = 'Password must contain atleast one';

  let mapCompleted = false;
  let capitalTested = false;
  let smallTested = false;
  let spCharacterTested = false;
  let numberTested = false;
  let lengthTested = false;
  let string = data.split('');
  passwordErrorMessage = 'Password must contain atleast one';
  if (data.length>0){
    string.map((letter,index) => {
      if (letter.match(regexCaptial)){
        capitalTested = true;
      }
      if (letter.match(regexSmall)){
        smallTested = true;
      }
      if (letter.match(regexSpCharacter)){
        spCharacterTested = true;
      }
      if (letter.match(regexNumber)){
        numberTested = true;
      }
      if (index === string.length -1){
        mapCompleted = true;
      }
      return '';
    });
    if (data.match(regexLength)){
      lengthTested = true;
    }
    if (mapCompleted){
      if (action === 'setPassword' || action === 'resetPassword') {
        return {capitalTested, smallTested, spCharacterTested, numberTested, lengthTested};
      }

      if (action === 'changePassword') {
        if (!capitalTested){
          passwordErrorMessage = (!smallTested || !spCharacterTested || !numberTested || !lengthTested) ? passwordErrorMessage + errorMsgForUppercase +',' : passwordErrorMessage + errorMsgForUppercase;
        }
        if (!smallTested){
          passwordErrorMessage = (!spCharacterTested || !numberTested || !lengthTested) ?  passwordErrorMessage + errorMsgForLowercase + "," : passwordErrorMessage + errorMsgForLowercase;
        }
        if (!spCharacterTested){
          passwordErrorMessage = (!numberTested || !lengthTested) ?  passwordErrorMessage + errorMsgForSpCharacter + "," : passwordErrorMessage + errorMsgForSpCharacter;
        }
        if (!numberTested){
          passwordErrorMessage = (!lengthTested) ? passwordErrorMessage + errorMsgForNumber + "," : passwordErrorMessage + errorMsgForNumber;
        }
        if (!lengthTested){
          passwordErrorMessage = passwordErrorMessage + errorMsgForLength;
        }
        return passwordErrorMessage;
      }
    }
  }
  else {
    passwordErrorMessage = '';
  }
}

function setTenantBrandColor() {
  let allElements = document.querySelectorAll('.tenant-brand-color');
  let allOpacityElements = document.querySelectorAll('.tenant-brand-color-opacity');

  if (allElements && allElements.length) {
    for (let i=0; i<=allElements.length-1; i++) {
      allElements[i].style.setProperty('--tenant-color', AppStore.get('brandColor'));
    }
  }
  if (allOpacityElements && allOpacityElements.length) {
    for (let i=0; i<=allOpacityElements.length-1; i++) {
      allOpacityElements[i].style.setProperty('--tenant-color-opacity', AppStore.get('brandColorOpacity'));
    }
  }
}

/*
Comment: check condition for titanDefAdmin role and return
Developer: Manohar
Date: 21/05/2020
*/

function aclWisePermissionAccess(coreEntity, action) {
  let userInfo1 = userInfo;
  if (!userInfo) userInfo1 = JSON.parse(localStorage.getItem('userInfo'));
  if(userInfo1 && (userInfo1.role === 'Silo Admin')) {
    return true;
  }
  else if (userInfo1 && userInfo1.userPermission && Object.keys(userInfo1.userPermission).length > 0 && userInfo1.userPermission[coreEntity]) {
    if (userInfo1.userPermission[coreEntity].length > 0 &&  this.hasPermission(userInfo1.userPermission[coreEntity], action)) {
      return true;
    } else {
      if ((userInfo.role === 'Silo Admin') && coreEntity === 'acl') {
        return true;
      }
      return false;
    }
  } else if (userInfo1 && userInfo1.rolePermission && Object.keys(userInfo1.rolePermission).length > 0 && userInfo1.rolePermission[coreEntity]) {
    if (this.hasPermission(userInfo1.rolePermission[coreEntity], action)) {
      return true;
    } else {
      return false;
    }
  } else if (userInfo1 && userInfo1.departmentPermission && Object.keys(userInfo1.departmentPermission).length > 0 && userInfo1.departmentPermission[coreEntity]) {
    if (this.hasPermission(userInfo1.departmentPermission[coreEntity], action)) {
      return true;
    } else {
      return false;
    }
  } else {
    if (userInfo1 && (userInfo.role === 'Silo Admin') && coreEntity === 'acl') {
      return true;
    }
    return false;
  }
}

/*
  Comment: Get Initials of DisplayName for Avatars
  Developer: Yashvi
  Date: 5 Feb 2022
*/
/*
  Comment: Modified Get Initials of DisplayName for Avatars
  Developer: Arun Singh
  Date: 1 Mar 2024
*/
function getInitials(displayName) {
  if (typeof displayName === "string") {
    // Remove extra spaces between words
    let cleanedDisplayName = displayName.replace(/\s+/g, ' ').trim();
    let splitDisplayName = cleanedDisplayName.split(" ");
    if (splitDisplayName.length > 1) {
      return (splitDisplayName[0][0].toUpperCase() + splitDisplayName[splitDisplayName.length - 1][0].toUpperCase());
    } else {
      if (splitDisplayName[0] === '') {
        return 'N/A';
      } else {
        return splitDisplayName[0][0].toUpperCase();
      }
    }
  }
}


function getDay(date) {
  const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  return days[ date.getDay() ];
}
function getMonth(date) {
  const months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
  return months[ date.getMonth() ];
}

function getWeeks(days) {
  let weeks = (days/7);
  if (weeks % 1 !== 0) {
    weeks = weeks.toFixed(1);
  }
  return Math.round(weeks);
}
function validateAadharNumber(aadharNumber) {
  /*
    Modification: T1948 - Added condition to first check if all numbers and aadharNumber has a value
    By: Devang
    Date: 15/07/2024
  */
  if (!aadharNumber || (aadharNumber && isNaN(aadharNumber))) return false;

  const d = [
	    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
	    [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
	    [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
	    [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
	    [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
	    [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
	    [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
	    [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
	    [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
	    [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
	]

	// permutation table
	const p = [
	    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
	    [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
	    [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
	    [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
	    [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
	    [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
	    [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
	    [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
	]

	// validates Aadhar number received as string
    let c = 0
    let invertedArray = aadharNumber.split('').map(Number).reverse()

    invertedArray.forEach((val, i) => {
        c = d[c][p[(i % 8)][val]]
    })

    return (c === 0)
}
function addBusinessDaysToDate(date, days) {
  let day = date.getDay();

  date = new Date(date.getTime());
  date.setDate(date.getDate() + days + (day === 0 ? 1 : +!day) + (Math.floor((days - 1 + (day % 0 || 1)) / 5) * 1));
  return date;
}

const registrationTypeMappingClassColor = {
  Taxi: 'yellow',
  'Non Taxi': 'light-brown'
};

const registrationTypeTextMappingClassColor = {
  Taxi: 'text-black',
  'Non Taxi': 'text-white'
};
const registrationTypeMapping = {
  'Individual (Owned House)': 'Non Taxi',
  'Individual (Rented House)': 'Non Taxi',
  'Corporate (Owned)': 'Non Taxi',
  'Corporate (Rented)': 'Non Taxi',
  'CRTM': 'Taxi',
  'NRI': 'Non Taxi',
  'BH': 'Non Taxi'
};
const typeOfEnquiryMappingClassColor = {
  Hot: 'dark-red',
  Cold: 'blue',
  Warm: 'orange',
};

const typeOfEnquiryMappingStyleColor = {
  Hot: '#f50422',
  Cold: '#14adc6',
  Warm: '#e86224',
}

function inWords (num) {
  let a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
  let b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
  if ((num = num.toString()).length > 9) return 'overflow';
  let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return; var str = '';
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
  return str;
}
  /*
  Modified By: Rutuja
  Mofieid On: 26 Jan 2024
  [Model Number, Variant List Issue: Some model and varients are still missing .They are comming un-evenly(UI issue).
  */
  /*
    BY: Prasannadatta Kawadkar
    ON: 27 March 2024
    Modification: changed Mapped values where ever necessary
  */
const modelMapping = {
  venue: 'Venue',
  'Venue': 'Venue',
  santro: 'Santro',
  'Santro': 'Santro',
  grandi10nios: 'Grand i10 Nios',
  'Grand i10 Nios': 'Grand i10 Nios',
  allnewi20: 'All New i20',
  'All New i20': 'All New i20',
  newcreta: 'New Creta',
  'New Creta': 'New Creta',
  konaev: 'Kona EV',
  'Kona EV': 'Kona EV',
  i20Nline: 'i20 N Line',
  'i20 N Line': 'i20 N Line',
  i20nline: 'i20 N Line',
  'i20 N Line': 'i20 N Line',
  alcazar: 'Alcazar',
  'Alcazar': 'Alcazar',
  xcentprime: 'Xcent Prime',
  'Xcent Prime': 'Xcent Prime',
  nextgenverna: 'Next Gen Verna',
  'Next Gen Verna': 'Next Gen Verna',
  aura: 'Aura',
  'Aura': 'Aura',
  tucson: 'Tucson',
  'Tucson': 'Tucson',
  ioniq5: 'Ioniq 5',
  'Ioniq 5': 'Ioniq 5',
  newtucson: 'New Tucson',
  'New Tucson': 'New Tucson',
  venuenline: 'Venue N Line',
  venueNline: 'Venue N Line',
  'Venue N Line': 'Venue N Line',
  allnewverna: 'All New Verna',
  exter: 'Exter',
  'all new verna': 'All New Verna',
  'All New Verna': 'All New Verna',
  'fortuner': 'FORTUNER',
  'FORTUNER': 'FORTUNER',
  'getz': 'GETZ',
  'GETZ': 'GETZ',
  'i 10': 'I10',
  'I 10': 'I10',
  'i20': 'I20',
  'I20': 'I20',
  'eon': 'EON',
  'EON': 'EON',
  'elantra': 'Elantra',
  'Elantra': 'Elantra',
  'i20 active': 'I20 ACTIVE',
  'I20 ACTIVE': 'I20 ACTIVE',
  'new i20 (ib)': 'NEW I20 (IB)',
  'NEW I20 (IB)': 'NEW I20 (IB)',
  'renault triber': 'RENAULT triber',
  'RENAULT triber': 'RENAULT triber',
  'xcent': 'XCENT',
  'XCENT': 'XCENT',
  'xcent vtvt sx(o)': 'XCENT VTVT SX(O)',
  'XCENT VTVT SX(O)': 'XCENT VTVT SX(O)',
  'all new i20': 'ALL NEW I20',
  'accent': 'ACCENT',
  'alcazer': 'ALCAZER',
  'alczar': 'ALCZAR',
  'all new tucson': 'ALL NEW TUCSON',
  'all new verna': 'ALL NEW VERNA',
  'aura': 'AURA',
  'aura  aura 1.2mt kappa sx': 'AURA  AURA 1.2MT KAPPA SX',
  'alcazar': 'Alcazar',
  'alcazar (1.5 tgdi-p)': 'Alcazar (1.5 TGDi-P)',
  'all new verna (mpi-p)': 'All New Verna (MPi-P)',
  'all new verna (tgdi-p)': 'All New Verna (TGDi-P)',
  'aura (p)': 'Aura (P)',
  'creat': 'CREAT',
  'cret 1.6 vtvt auto sx +': 'CRET 1.6 VTVT AUTO SX +',
  'creta': 'CRETA',
  'creta (d)': 'CRETA (D)',
  'creta (p)': 'CRETA (P)',
  'creta 1.4 crdi s +': 'CRETA 1.4 CRDI S +',
  'creta 1.6 crdi auto': 'CRETA 1.6 CRDI AUTO',
  'creta 1.6 crdi auto sx +': 'CRETA 1.6 CRDI AUTO SX +',
  'creta 1.6 crdi sx': 'CRETA 1.6 CRDI SX',
  'creta 1.6 vtvt': 'CRETA 1.6 VTVT',
  'creta 1.6 vtvt s': 'CRETA 1.6 VTVT S',
  'creta 1.6 vtvt sx +': 'CRETA 1.6 VTVT SX +',
  'creta.': 'CRETA.',
  'crta': 'CRTA',
  'creta d': 'Creta D',
  'creta facelift': 'Creta Facelift',
  'e.f.sonata': 'E.F.Sonata',
  'ef sonata': 'EF SONATA',
  'elantra fluidic': 'ELANTRA FLUIDIC',
  'eon': 'EON',
  'exter': 'EXTER',
  'elantra': 'Elantra',
  'elantra (adi) d': 'Elantra (ADi) D',
  'elantra (adi) p': 'Elantra (ADi) P',
  'elite i20 (1.2p)': 'Elite i20 (1.2P)',
  'exter': 'Exter',
  'fortuner': 'FORTUNER',
  'garnd i10': 'GARND I10',
  'grand': 'GRAND',
  'grand i10 nios': 'GRAND I10 NIOS',
  'grand i10 sportz': 'GRAND I10 SPORTZ',
  'grand i10 (d)': 'GRAND i10 (D)',
  'grand i10 (p)': 'GRAND i10 (P)',
  'grnd i10': 'GRND I10',
  'genesis': 'Genesis',
  'getz': 'Getz',
  'grand i10': 'Grand i10',
  'grand i10 nios (1.2 d)': 'Grand i10 NIOS (1.2 D)',
  'grand i10 nios (1.2 p)': 'Grand i10 NIOS (1.2 P)',
  'honda city': 'HONDA CITY',
  'i 10': 'I10',
  'i 20': 'I20',
  'i-10': 'I-10',
  'i10 magna': 'I10 MAGNA',
  'i20': 'I20',
  'i20 active': 'I20 ACTIVE',
  'i20 n line': 'I20 N LINE',
  'i20 sportz 1.2': 'I20 SPORTZ 1.2',
  'ioniq 5': 'IONIQ 5',
  'kona': 'KONA',
  'kona ev': 'KONA EV',
  'kona (electric)': 'Kona (Electric)',
  'liberty': 'LIBERTY',
  'magna': 'MAGNA',
  'neo elantra': 'NEO ELANTRA',
  'new i20 (ib)': 'NEW I20 (IB)',
  'new i20 active vtvt sx': 'NEW I20 ACTIVE VTVT SX',
  'new santafe(dm)': 'NEW SANTAFE(DM)',
  'new santro (1.1 p)': 'NEW SANTRO (1.1 P)',
  'new sonata': 'NEW SONATA',
  'new verna': 'NEW VERNA',
  'NEW VERNA': 'NEW VERNA',
  'new xcent (p)': 'NEW XCENT (P)',
  'new creta': 'New Creta',
  'new creta (1.4p)': 'New Creta (1.4P)',
  'new creta (1.5 p)': 'New Creta (1.5 P)',
  'new creta (1.5d)': 'New Creta (1.5D)',
  'new santa fe (dm)': 'New Santa Fe (DM)',
  'new tucson': 'New Tucson',
  'new tucson (nx-d)': 'New Tucson (NX-D)',
  'new tucson (nx-p)': 'New Tucson (NX-P)',
  'new i20': 'New i20',
  'new i20 (1.0 p)': 'New i20 (1.0 P)',
  'new i20 (1.2 p)': 'New i20 (1.2 P)',
  'new i20 (1.5 d)': 'New i20 (1.5 D)',
  'next gen verna (1.6p)': 'Next Gen VERNA (1.6P)',
  'next gen verna (d)': 'Next Gen VERNA (D)',
  'next gen verna(1.6p)': 'Next Gen VERNA(1.6P)',
  'next gen verna': 'Next Gen Verna',
  'next gen verna (1.5d)': 'Next Gen Verna (1.5D)',
  'next gen verna g1.6p': 'Next Gen Verna G1.6P',
  'santa fe (cmi)': 'SANTA FE (CMi)',
  'santa fe (dmi)': 'SANTA FE (DMi)',
  'santo': 'SANTO',
  'santro': 'SANTRO',
  'santro.': 'SANTRO.',
  'santa fe': 'Santa Fe',
  'santa fe cbu': 'Santa Fe CBU',
  'santafe': 'Santafe',
  'sonata': 'Sonata',
  'tata': 'TATA',
  'terracan': 'TERRACAN',
  'tucson': 'TUCSON',
  'tucson.': 'TUCSON.',
  'tucson (tli) d': 'Tucson (TLi) D',
  'tucson (tli) p': 'Tucson (TLi) P',
  'venue': 'VENUE',
  'venue n line': 'VENUE N LINE',
  'verana': 'VERANA',
  'verna': 'VERNA',
  'verna fl 1.6 crdi sx': 'VERNA FL 1.6 CRDI SX',
  'verna fluidic': 'VERNA FLUIDIC',
  'verna.': 'VERNA.',
  'venue (1.0) p': 'Venue (1.0) P',
  'venue (1.2) p': 'Venue (1.2) P',
  'Venue (1.2) P': 'Venue (1.2) P',
  'venue (1.4) d': 'Venue (1.4) D',
  'venue (1.4/1.5) d': 'Venue (1.4/1.5) D',
  'vunue': 'Vunue',
  'wagon r 1.0': 'Wagon R 1.0',
  'xcent': 'XCENT',
  'xcent (d)': 'Xcent (D)',
  'xcent (p)': 'Xcent (P)',
  'alcazar': 'alcazar',
  'allnewi20': 'All New I20',
  'grandi10nios': 'Grand i10 Nios',
  'hdfc': 'hdfc',
  'i10': 'I10',
  'i11': 'I11',
  'i20': 'I20',
  'i20 active(1.2p)': 'I20 Active(1.2P)',
  'i20 active(1.4d)': 'I20 Active(1.4D)',
  'ioniq5': 'ioniq 5',
  'newcreta': 'newcreta',
  'royal': 'royal',
  'tuson': 'tuson',
  'venuenline': 'Venue N Line',
  'verna.': 'VERNA',
  'Verna': 'VERNA',
  'verna fluidic': 'VERNA fluidic',
  'venue n line': 'Venue N Line',
  'santro old': 'Santro Old',
  'sonata nfi': 'Sonata NFI',
  'new santafe(dm)': 'New Santafe(DM)',
  'new sonata': 'New Sonata',
  'santa fe': 'Santa Fe',
  'i10': 'i10',
  'grand i10': 'Grand i10',
  'old creta': 'Creta',
  'elantra fluidic': 'Elantra fluidic',
  'accent': 'Accent',
  'santro xing': 'Santro Xing',
  'getz prime': 'Getz Prime'
};

const modelCodeMapping = {
  venue: 'SP',
  santro: 'C4',
  grandi10nios: 'HQ',
  allnewi20: 'SV',
  i20Nline: 'SW',
  alcazar: 'AA',
  xcentprime: 'XX',
  nextgenverna: "H6",
  aura: 'ZZ',
  newcreta: 'FH',
  tucson: '9M',
  konaev: 'HO',
  newtucson: '9M',
  venuenline: 'SQ',
  ioniq5: '6I',
  bn71: '0Y'
};

function getDifferenceInDays(date) {
  if (!date) {
    return null;
  }
  // calculation of no. of days between two date

  // To set two dates to two variables
  let date1 = new Date(date);
  let date2 = new Date();

  // To calculate the time difference of two dates
  let Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  return (Difference_In_Days).toFixed(0);
}

function isString(val) {
  return Object.prototype.toString.call(val) === '[object String]';
}

function isValidDate(val) {
  var timestamp = Date.parse(val);

  if (isNaN(timestamp) == false) {
    return true
  }
  return false
}

function getStageAcronym(stageName) {
  if (stageName) {
    stageName = stageName.toLowerCase();
    if (stageName === 'enquiry') return 'ENQ.';
    else if (stageName === 'booking') return 'BKG.';
    else if (stageName === 'retail') return 'RTL.';
    else if (stageName === 'delivery') return 'DLVRY.';
  }
}

function getMomentCalendarTimeLabel(date, isYearNeeded) {
	return moment(date).calendar(null, {
		sameDay: '[Today]',
		nextDay: '[Tomorrow]',
		nextWeek: 'dddd',
		nextWeek: 'dddd',
		lastDay: '[Yesterday]',
		lastWeek: '[Last] dddd',
		sameElse: isYearNeeded ? 'DD/MM/YYYY' : 'DD MMM',
	});
}

function formatDateTimeRelative(date) {
	let d = getMomentCalendarTimeLabel(date, true);
	if (d.includes('/')) {
		let getDateYear = d.split('/') && d.split('/')[2];
		let currentYear = moment().format('YYYY');
		if (getDateYear === currentYear) d = getMomentCalendarTimeLabel(date, false);
	}
	return d;
}

function getDateColor (date) {
  if (date && date.toLowerCase() === 'today') return '#6bc950';
  return ''
}

function capitalizeFirstLetter(string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
}

function isObject(obj) {
  return typeof obj === 'object' &&
    !Array.isArray(obj) &&
    obj !== null;
}
/*
  modified : Ashutosh G
  modified : 15/01/2024
  modification : function to make all world capitalize
*/
function allWorldCapitalize(sentence) {
  return sentence.split(" ").map(word => capitalizeFirstLetter(word)).join(" ");
}

function isEmptyObject(obj){
    return JSON.stringify(obj) === '{}'
}

function removeFalseKeyValuePairs(obj) {
  Object.keys(obj).filter((key) => {
    if (Array.isArray(obj[key]) && !obj[key].length) {
      delete obj[key];
    } else if (!(Boolean(obj[key]))) {
      delete obj[key];
    }
  });
  return obj;
}

function getZeroPrefixedNumber(num, size) {
  /**
   * returns string of number accepted as num parameter with prefix of '0' to fix length of size given
   * @param {number} num - number sent by user. eg - 2023
   * @param {number} size - length of num restricting min-length by giving '0' prefix. eg - 2002
   */
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function getPreviousYearRange(start, stop, step) {
  /**
   * returns array of years from starting to end with gap of step_value
   * @param {number} start - starting Year. eg - 2023
   * @param {number} stop - ending Year. eg - 2002
   * @param {number} step - gap between years of array (should be negative value), eg - -1
   */
  return Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step)) ;
}

function sortList(rows, col, isSortAscending) {
  let sortBy = col.sortableField || col.field; // first preference to sortableField
  let sortType = col.sortType && col.sortType.toLowerCase(); // string, array, date, number, null
  if (sortBy) {
    if (sortType === 'string' || !sortType) {
      if (isSortAscending) {
        rows = rows.sort((a, b) => {
          return (a[sortBy] || '').localeCompare(b[sortBy] || '');
        });
      } else {
        rows = rows.sort((a, b) => {
          return (b[sortBy] || '').localeCompare(a[sortBy] || '');
        });
      }
    } else if (sortType === 'array') {
      if (isSortAscending) {
        rows = rows.sort((a, b) => {
          return a[sortBy].length - b[sortBy].length;
        });
      } else {
        rows = rows.sort((a, b) => {
          return b[sortBy].length - a[sortBy].length;
        });
      }
    } else if (sortType === 'date') {
      if (isSortAscending) {
        rows = rows.sort((a,b) => {
          return new Date(a[sortBy] ||  new Date()) - new Date(b[sortBy] || new Date());
        });
      } else {
        rows = rows.sort((a,b) => {
          return new Date(b[sortBy] || new Date()) - new Date(a[sortBy] ||  new Date());
        });
      }
    } else if (sortType === 'number') {
      if (isSortAscending) {
        rows = rows.sort((a,b) => {
          return (a[sortBy] || 0) - (b[sortBy] || 0);
        });
      } else {
        rows = rows.sort((a,b) => {
          return (b[sortBy] || 0) - (a[sortBy] || 0);
        });
      }
    }
  }
  return rows;
}

function generateYearsBetween(startYear, endYear = new Date().getFullYear()) {
  let years = [];
  for (let i = startYear; i <= endYear; i++) {
    years.push(startYear);
    startYear++;
  }
  return years;
}
function getExpiryDateAfterYears(date = new Date(), numOfYear = 1) {
  date = new Date(date);
  return date.setFullYear(date.getFullYear() + numOfYear) - 24*60*60*1000;
}

function titleCase(sentence) {
  let wordsArr = sentence.toLowerCase().split(' ');
  wordsArr = wordsArr.map(word => word.charAt(0).toUpperCase() + word.substring(1))
  return wordsArr.join(' ');
}

function getTextWithHighlightMatching(text, searchText, highlightedStyle = {}) {
  const {
    textColor = '#000', // 000AFF - old blueish text color
    bgColor = '#fbee32'
  } = highlightedStyle

  // let searchTextRegex = new RegExp(searchText, "i");
  let searchTextInnerRegex = ``;
  searchText.split(' ').filter(text => text).map(text => {
    searchTextInnerRegex += `(?=.*${text})`;
  });
  let searchTextRegex = new RegExp(searchTextInnerRegex, "i");
  let highlightedText = text;
  if (text && searchText) {
    highlightedText = (text.replace(searchTextRegex, match => `<span style="color: ${textColor}; background: ${bgColor}">${match}`)) + '</span>';
  }
  console.log(highlightedText, text && searchText, 'highlightedTexthighlightedTexthighlightedText')
  return {__html: highlightedText};
};

/*
  Bug: T1962 - Add text search in case document view for searching the document
  Modification: Added function to render highlighted with another search method
  By: Devang
  Date: 11/07/2024
*/
const renderHighlighted = (sentence, searchText) => {
    const highlightText = (sentence) => {
    if (!searchText) return sentence;
    const words = searchText.split(/\s+/).filter(Boolean);
    const regex = new RegExp(`(${words.join('|')})`, 'gi');
    return sentence.replace(regex, '<span style="background-color: yellow">$1</span>');
  };

  return (
    <p variant="body1" dangerouslySetInnerHTML={{ __html: highlightText(sentence) }} />
  );
};

/*
  Modification: added new function to highlight each word with match
  By: Masum Raja
  Date: 24/02/2024
*/

function getCharacterWithHighlightMatching(text, searchText, highlightedStyle = {}) {
  const {
    textColor = '#000',
    bgColor = '#fbee32'
  } = highlightedStyle;

  if (!text || !searchText) {
    return {__html: text}; // Return the original text if either text or searchText is empty
  }

  // Escape special characters in the search query
  const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // Create a regular expression for each word in the search query
  const searchTextRegex = new RegExp(escapedSearchText.split(/\s+/).join('.*'), "gi");

  // Replace matched text with HTML tags for highlighting
  const highlightedText = text.replace(searchTextRegex, match => `<span style="color: ${textColor}; background: ${bgColor}">${match}</span>`);

  return {__html: highlightedText};
}

function removeDuplicates(arr) {
  return [...new Set(arr)];
}

function minTwoDigits(n) {
  return (n < 10 ? '0' : '') + n;
}

function formatDateInput(date) {
  date = new Date(date);
  let month = date.getUTCMonth() + 1;
  let day = date.getUTCDate();
  let year = date.getUTCFullYear();
  let formattedDate = `${minTwoDigits(year)}-${minTwoDigits(month)}-${minTwoDigits(day)}`
  return formattedDate;
}

function camelCaseToNormalCase(str) {
  return str.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase()).trim();
}

function normalCaseToCamelCase(text) {
  const words = text.trim().split(' ');

  const camelWords = words.map((word, index) => {
    if (index === 0) {
      return word;
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  const camelCaseText = camelWords.join('');
  return camelCaseText;
}

function throwError(msg) {
  throw new Error(msg);
}

function assignFiltersFromAnotherRoute(filterDispatchEntityKey, keyNeeded) {
  let [state, dispatch] = useContext(ContextOne);
  if (state.filters && state.filters[filterDispatchEntityKey]) {
    return state.filters[filterDispatchEntityKey][keyNeeded];
  }
  return
}

function delegateClick(id) {
  let div = document.getElementById(id);
  if (div)
    div.click();
}

const fuelTypeMapper = {
  all: 'All',
  cng: 'CNG',
  diesel: 'Diesel',
  electric: 'Electric',
  petrol: 'Petrol'
}

const blockInvalidNumberChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

const formatNamesArr = (names) => {
  if (names.length === 0) {
    return '';
  } else if (names.length === 1) {
    return names[0];
  } else if (names.length === 2) {
    return names.join(' and ');
  } else {
    const last = names.pop();
    return `${names.join(', ')}, and ${last}`;
  }
}

const isAllowedFileType = (f, customFileType) => {
  const filetypes = customFileType || ['png', 'jpg', 'jpeg', 'pdf'];
  let fileName = f.name;
  let extension = fileName.split('.').pop();
  if (filetypes.includes(extension)) {
    return true
  } else {
    return false
  }
}

function formatValidationMonthPattern(givenDate) {
  let date = givenDate ? new Date(givenDate) : new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  return `${year}-${month < 10 ? `0${month}` : `${month}`}`
}
/*
  modified : Ashutosh G
  modified : 05/02/2024
  modification : getDashBoardDate function added
*/
function getDashBoardDate(date = new Date()) {
  return moment(date).format('ddd, DD')
}
function isEmpty(value) {
    return (
        value === undefined || // Check for undefined
        value === null || // Check for null
        value === '' || // Check for empty string
        (Array.isArray(value) && value.length === 0) || // Check for empty array
        (typeof value === 'object' && Object.keys(value).length === 0) // Check for empty object
    );
}
const processMapping = {
  /*
    Bug: T1851 - BG_FN: CRM/Backoffice Tiles & Tabs - Counts are not correct
    Modification: Added Other in all stages
    By: Devang
    Date: 10/06/2024
  */
  Lead: ['New', 'Open', 'Lost', 'Won', "Other"],
  Enquiry: ['Open', 'Pre-booked', 'Lost', 'Won', "Other"],
  Booking: ['Booked', 'Document', 'Sign', 'Allotment', 'Checklist', 'Cancelled', "Other"],
  /*
    Modification: T1660 - Changed booked options to all
    By: Devang
    Date: 29/03/2024
  */
  // Booked: ['Booked', 'Document', 'Sign', 'Allotment', 'Checklist', 'Cancelled'],
  Booked: ["All"],
  /*
    Bug: T1687 - 50 - My Tasks , Back Office Cases - Retail Stage - Sequence - RTO -> Insurance -> Claim - The sequence should be Claim -> Insurance -> RTO
    Modification: Changed the order
    By: Devang
    Date: 12/04/2024
  */
  Retail: ['Invoice', 'Claim', 'Insurance', 'RTO', 'Vahan', 'Delivery Planning', "Other"],
  Delivery: ['Stockyard', 'Showroom', 'Audit', 'Delivery', 'Delivered', "Other"],
  /*
    modified : Ashutosh G
    modified : 19/03/2024
    modification : Post-Delivery added
  */
  "Post-Delivery": ["All"],
  /*
    Modification: Renamed Customer Profile as Customer Info
    By: Devang
    Date: 16/03/2024
  */
  /*
    Bug: T1687 - 38 - Reword "Ammendments" with "Amendments"
    Modification: Reworded "Ammendments" with "Amendments"
    By: Devang
    Date: 10/04/2024
  */
  Amendments: ['Customer Info', 'Cart'],
  leadToEnquiryPending: ['Today', 'Pending', 'Queued'],
  leadToEnquiryCompleted: ['Showroom Visit', 'Test Drive', 'Wants Brochure', 'Wants price list', 'Wants payment receipt', 'Wants docket'],
  enquiryToBookingPending: ['Today', 'Pending', 'Queued'],
  enquiryToBookingCompleted: ['Showroom Visit', 'Test Drive', 'Wants Brochure', 'Wants price list', 'Wants payment receipt', 'Wants docket'],
  postBookingPending: ['Today', 'Pending', 'Queued'],
  postBookingCompleted: ['All'],
  postDeliveryPending: ['Today', 'Pending', 'Queued'],
  postDeliveryCompleted: ['All'],
  /*
    Modification: postDeliveryCompleted, postBookingCompleted has All Actions
    Developer: Manohar sule
    Date: 22/03/2024
  */
};

/*
  modified : Ashutosh G
  modified : 01/03/2024
  modification : formatTimeString added
*/
function formatTimeString(dateString) {
  const dateObject = new Date(dateString);

  const timeString = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true // 12-hour clock format
  }).format(dateObject);

  return timeString;
}


/*
  Modification: T319 - Added Debounce function to limit the rate of function execution
  By: Devang
  Date: 20/05/2024
*/
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const showConfettiTasks = ["buy renewal policy", "buy new policy"];

/*
  Bug: T1843 - EN - Insurance -Add New Insurance Renewal Case - While adding a case System should check if VIN is already existing.
  Modification: Took func from COMP
  By: Devang
  Date: 02/07/2024
*/
const vinNumberValidation = (value) => {
  const regex = new RegExp("^[a-zA-Z0-9]{17}$");
  return regex.test(value)
}

/*
  Modification: Exported all variables and functions at the end of the file
  By: Devang
  Date: 26/03/2024
*/
export {
  months,
  monthName,
  getMonthNumber,
  calculatePercentage,
  truncateString,
  formatDateWithoutTime,
  formatTimeIn12HourFormat,
  formatDateTime,
  formatDateTimeForDashboard,
  formatTime,
  getFormattedAmount,
  applyAclForFeedAndChat,
  startLoader,
  stopLoader,
  getCommaSeparatedValues,
  amountInWordsConversion,
  convertArrayOfObjectsToCSV,
  downloadCSV,
  stripEmpty,
  removeEmptyObjects,
  passwordErrorMessageText,
  setTenantBrandColor,
  aclWisePermissionAccess,
  getInitials,
  getDay,
  getMonth,
  getWeeks,
  validateAadharNumber,
  addBusinessDaysToDate,
  registrationTypeMappingClassColor,
  registrationTypeTextMappingClassColor,
  registrationTypeMapping,
  typeOfEnquiryMappingClassColor,
  typeOfEnquiryMappingStyleColor,
  inWords,
  modelMapping,
  modelCodeMapping,
  getDifferenceInDays,
  isString,
  isValidDate,
  getStageAcronym,
  getMomentCalendarTimeLabel,
  formatDateTimeRelative,
  getDateColor,
  capitalizeFirstLetter,
  isObject,
  isEmptyObject,
  removeFalseKeyValuePairs,
  getZeroPrefixedNumber,
  deepCopy,
  getPreviousYearRange,
  sortList,
  generateYearsBetween,
  getExpiryDateAfterYears,
  titleCase,
  getTextWithHighlightMatching,
  getCharacterWithHighlightMatching,
  removeDuplicates,
  minTwoDigits,
  formatDateInput,
  camelCaseToNormalCase,
  normalCaseToCamelCase,
  throwError,
  assignFiltersFromAnotherRoute,
  delegateClick,
  fuelTypeMapper,
  blockInvalidNumberChar,
  formatNamesArr,
  isAllowedFileType,
  getDashBoardDate,
  isEmpty,
  processMapping,
  formatTimeString,
  allWorldCapitalize,
  renderHighlighted,
  debounce,
  showConfettiTasks,
  vinNumberValidation,
  formatValidationMonthPattern
};
