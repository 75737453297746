import { h, Component } from 'preact';
import { useReducer } from 'preact/hooks';
import { useEffect, useState, useRef } from 'preact/hooks';
import { lazy, Suspense } from 'preact/compat';
import { route } from 'preact-router';
import { Router } from 'preact-router';
import { Match } from 'preact-router/src/match';

import convertVapidKey from 'convert-vapid-public-key';
import io from "socket.io-client";
import axios from 'axios';
import NetworkInformationApiPolyfill from '../../node_modules/network-information-api-polyfill/src';

import { AppStore } from '../lib/store';
import { getItem, setItem } from '../lib/myStore';
import CONSTANTS from '../lib/constants';
import { unProtectedRoutes } from '../lib/routes';
import { caseDetailsReducer, initialState } from '../components/reducerStore';

import LeftInfoPanel from '../components/leftInfoPanel';
import RightPanel from '../components/rightPanel';
import SlowInternetSpeed from '../components/slowInternet';
import NoInternet from '../components/noInternet';
import ContextOne from '../components/context';
import Chatbot from '../components/chatBot';
import NavbarV2 from '../components/navbarV2';

import { UsersProvider } from "../components/chat/context/usersContext";
import { SocketProvider } from "../components/chat/context/socketContext";

const BrowserNotSupported = lazy(() => '../routes/pages/browserNotSupport');
const Login = lazy(() => import('../routes/login'));
const WorkspaceV3 = lazy(() => import('../routes/workspaceV3'));
const SetNewPassword = lazy(() => import('../routes/setNewPassword'));
const SetPasswordToVerify = lazy(() => import('../routes/setPasswordToVerify'));
const Profile = lazy(() => import('../routes/profile'));
const Workspacedetails = lazy(() => import('../routes/workspacedetails'));
const WorkspaceCaseDetailsV2 = lazy(() => import('../routes/workspaceCaseDetailsV2'));
const SiloAdministration = lazy(() => import('../routes/siloAdministration'));
const Organization = lazy(() => import('../routes/organization'));
const CaseDetailLogin = lazy(() => import('../routes/caseDetailLogin'));
const Esign = lazy(() => import('../routes/esignPage'));
const EsignatureInitiate = lazy(() => import('../routes/esignatureInitiate'));
const CaseCommitment = lazy(() => import('../routes/caseCommitment'));
const ProjectTracker = lazy(() => import('../routes/dashboard'));
const ProcessMapping = lazy(() => import('../routes/processMapping'));
const Checklist = lazy(() => import('../routes/checklist'));
const AclPermissions = lazy(() => import('../routes/aclPermissions'));
const StockDashboard2 = lazy(() => import('../routes/stockDashboard2'));
const OrderDashboard = lazy(() => import('../routes/orderDashboard'));
const NotificationDashboard = lazy(() => import('../routes/notificationDashboard'));
const CaseView = lazy(() => import('../routes/caseView'));
const ProcessView = lazy(() => import('../routes/processView'));
const Search = lazy(() => import('../routes/search'));
const Analytics = lazy(() => import('../routes/analytics'));
const JobWorkspace = lazy(() => import('../routes/jobWorkspace'));
const SchedulerDashboard = lazy(() => import('../routes/schedulerDashboard'));
const SchedulerList = lazy(() => import('../routes/schedulerList'));
const WorkspaceApprovalDetailsV3 = lazy(() => import('../routes/workspaceApprovalDetailsV3'));
const CaseManagementV4 = lazy(() => import('../routes/caseManagementV4'));
const WorkspaceCouponDetails = lazy(() => import('../routes/workspaceCouponDetails'));
const WorkspacePolicyCouponDetails = lazy(() => import('../routes/workspacePolicyCouponDetails'));
const TicketList = lazy(() => import('../routes/ticketList'));
const TicketKanban = lazy(() => import('../routes/ticketKanban'));
const CollaboratorChat = lazy(() => import('../routes/collaboratorChat'));
const SalesDashboard = lazy(() => import('../routes/salesDashboard'));
const HomeV2 = lazy(() => import('../routes/homeV2'));
const SalesCRMV2 = lazy(() => import('../routes/salesCRMV2'));
const InsuranceRenewalV2 = lazy(() => import('../routes/insuranceRenewalV2'));
const SalesCRMCases = lazy(() => import('../routes/salesCRMCases'));
const SalesBackOfficeCases = lazy(() => import('../routes/salesCRMCases'));
const SalesFollowups = lazy(() => import('../routes/salesFollowups'));
const HomeV3 = lazy(() => import('../routes/home'));
const Contact = lazy(() => import('../routes/contact'));
const VehicleStock = lazy(() => import('../routes/vehicleStock'));
const FormViewer = lazy(() => import('../routes/formViewer'));
const CollaboratorAI = lazy(() => import('../routes/collaboratorAI'));
const SalesHomeV2 = lazy(() => import('../routes/salesHomeV2'));
const MyTasks = lazy(() => import('../routes/myTasks'));
const DigitalAllocation = lazy(() => import('../routes/digitalAllocation'));
const EntityFormMapping = lazy(() => import("../routes/entityFormMapping"));
const Ticketing = lazy(() => import("../routes/ticketing"));
const TicketDetails = lazy(() => import('../routes/ticketDetails'));
const DataStatusDashboard = lazy(() => import('../routes/dataStatusDashboard'));
const CaseStatus = lazy(() => import('../routes/caseStatus'));
/*REPORT_COMMENTED_BY_DJ import Reports from '../routes/reports';*/

const App = () => {
// export default class App extends Component {
/** Gets fired when the route changes.
 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
 *	@param {string} event.url	The newly routed URL
 */
  const socket = io(`${CONSTANTS.SOCKETAPI_URL}`);
  const [state, dispatch] = useReducer(caseDetailsReducer, initialState);
  const [isBrowserNotSupported, setIsBrowserNotSupported] = useState(false);
  const [isLeftNavExpanded, setIsLeftNavExpanded] = useState(false);
  const [isOuterDivNeeded, setIsOuterDivNeeded] = useState(false);
  const [isSlowInternetSpeed, setIsSlowInternetSpeed] = useState(false);
  const [slowInternet, setSlowInternet] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.screen.availWidth < 481);
  let [isOngoingCallWindowOpen,setOngoingCallWindowOpen] = useState(false);
  let [trueCallerData, setTrueCallerData]= useState({});
  let [currentInteraction, setCurrentInteraction]= useState({});
  let [showLeftPanel, setShowLeftPanel] = useState(false);
  let [showMobileFooter, setShowMobileFooter] = useState(true);
  let [goingUp, setGoingUp] = useState(true);
  const [isTicketListUpdated, setIsTicketListUpdated] = useState(false);
  const prevScrollY = useRef(-1);
  let customerInfo = getItem('token');
  let [alertNotificationCount, setAlertNotificationCount] = useState(0);
  let [isCollaboratorListSearchInput, setIsCollaboratorListSearchInput] = useState('');

  /*
    Modification: T1654 - Added function to determine to go to either workspace or root page based on existence of token
    By: Devang
    Date: 26/03/2024
  */
  const designationForRenewalMapper = ["pick-up executive", "insurance agent", "insurance team lead", "insurance head", "back-office executive", "accounts executive"];
  const designationForReceptionistMapper = ["receptionist", "customer care manager"];
  const designationForLogisticsMapper = ["sales co-ordinator"];

  useEffect(() => {
    const token = getItem('token');
    if ((token && Object.keys(token).length)) {
      if (unProtectedRoutes.includes(location.pathname)) {
        if (AppStore.get('userinfo') && Object.keys(AppStore.get('userinfo')).length) {
          const userInfo = getItem('userinfo');
          if (designationForRenewalMapper.includes(userInfo.userDesignation.toLowerCase())) {
            window.history.replaceState({}, document.title, '/home');
            route('/home');
          } else if (designationForReceptionistMapper.includes(userInfo.userDesignation.toLowerCase())) {
            window.history.replaceState({}, document.title, '/workspace');
            route('/workspace');
          } else if (designationForLogisticsMapper.includes(userInfo.userDesignation.toLowerCase())) {
            window.history.replaceState({}, document.title, '/stockDashboard');
            route('/stockDashboard');
          } else if (userInfo.isSiloAdmin) {
            window.history.replaceState({}, document.title, '/organization');
            route('/organization');
          } else  {
            window.history.replaceState({}, document.title, '/salesHomeV2');
            route('/salesHomeV2');
          }
        }
      }
    } else {
      if (!unProtectedRoutes.includes(location.pathname)) {
        route('/');
      }
    }
  }, []);


  useEffect(() => {
    let connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection;
    console.log(connection);
    let type;
    if (connection) {
      type = connection.effectiveType;
      console.log(parseFloat(connection.downlink),"parseFloat(connection.downlink)parseFloat(connection.downlink)");
      if (parseFloat(connection.downlink) <= 0.05) {
        console.log('innnnnnnnnnn');
        setIsSlowInternetSpeed(true);
        console.log("slow internet", isSlowInternetSpeed);
      } else {
        console.log('elseeeeeeeeeeee');
        setIsSlowInternetSpeed(false);
      }
    } else {
      new NetworkInformationApiPolyfill().then(async (connection) => {
        if (parseFloat(connection.downlink) <= 0.6){
          setSlowInternet(true);
        }
        if (parseFloat(connection.downlink) > 0.6){
          setSlowInternet(false);
        }
      });
    }

    function updateConnectionStatus() {
      if (connection) {
        type = connection.effectiveType;
        if (parseFloat(connection.downlink) <= 0.6) {
          setIsSlowInternetSpeed(true);
          // that.setState({isSlowInternetSpeed: true});
        } else {
          setIsSlowInternetSpeed(false);
          // that.setState({isSlowInternetSpeed: false});
        }
      } else {
        new NetworkInformationApiPolyfill().then(async (connection) => {
          console.log("conntttttttt", connection);
          if (parseFloat(connection.downlink) <= 0.6){
            setSlowInternet(true);
            // await this.setState({slowInternet:true});
          }
          if (parseFloat(connection.downlink) > 0.6){
            setSlowInternet(false);
            // await this.setState({slowInternet:false});
          }
        });
      }
    }

    if (connection) {
      connection.addEventListener('change', updateConnectionStatus);
    }
    let isOnline = window.navigator.onLine;
    if (isOnline) {
      setIsOffline(false);
    } else {
      setIsOffline(true);
      // this.setState({ isOffline: true });
    }
    window.addEventListener('offline', () => {
      setIsOffline(true);
      setIsSlowInternetSpeed(false);
      // this.setState({ isOffline: true, isSlowInternetSpeed:false });
      return;
    });
    window.addEventListener('online', () => {
      setIsOffline(false);
      // window.location.reload();
    });
  },[]);

  useEffect(() => {
    let browserResult = getBrowserVersion();
    console.log("browserResult",browserResult);
    // need to add userAgent value for MS Edge
    if (browserResult === "Other") {
      setIsBrowserNotSupported(false);
      console.log(isBrowserNotSupported);
      // this.setState({isBrowserNotSupported:false});
    } else if ((browserResult === "Edge") || (browserResult >= 9 && browserResult < 10) || (browserResult >= 11 && browserResult < 12)) {
      setIsBrowserNotSupported(true);
    }
  }, []);

  useEffect(async() => {
    let token = getItem('token');
    console.log('Token Found');
    if (Object.keys(token).length) {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        const localStorage = getItem('subscribe').isSubscribed;
        if (!localStorage) {
          console.log('!localStorage');
          if ('Notification' in window) {
            console.log(window, 'windowwindowwindow');
            console.log(navigator, 'navigatornavigatornavigator Okayyyyy');
            if ('serviceWorker' in navigator) {
              // Register a service worker hosted at the root of the
              // site using the default scope.
              await navigator.serviceWorker.register('/sw.js').then(async (registration) => {
                console.log('Service worker registration succeeded:', registration);
                await Notification.requestPermission(async (status) => {
                  if (status === 'granted') {
                    subscribeUser(isMobile);
                  }
                });
              }, /*catch*/ (error) => {
                console.error(`Service worker registration failed: ${error}`);
              });
            } else {
              console.error('Service workers are not supported.');
            }
          }
        }
      }
      if (!isMobile) {
        const localStorage = '';
        // getItem('subscribe').isSubscribed;
        if (!localStorage) {
          if ('Notification' in window) {
            console.log('localStorage desktop', window, navigator);
            if ('serviceWorker' in navigator) {
              // Register a service worker hosted at the root of the
              // site using the default scope.
              await navigator.serviceWorker.register('/sw.js').then(async (registration) => {
                console.log('Service worker registration succeeded:', registration);
                await Notification.requestPermission(async (status) => {
                  if (status === 'granted') {
                    subscribeUser(isMobile);
                  }
                });
              }, /*catch*/ (error) => {
                console.error(`Service worker registration failed: ${error}`);
              });
            } else {
              console.error('Service workers are not supported.');
            }
          }
        }
      }
    }
  },[]);

  function subscribeUser(isMobile) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.pushManager.getSubscription()
          .then(pushSubscription => {
            if (!pushSubscription) {
              //the user was never subscribed
              subscribe(registration, isMobile);
            } else {
              //check if user was subscribed with a different key
              let json = pushSubscription.toJSON();
              let public_key = json.keys.p256dh;

              console.log(public_key);

              if (public_key != CONSTANTS.publicVapidKey) {
                pushSubscription.unsubscribe().then(successful => {
                  // You've successfully unsubscribed
                  subscribe(registration, isMobile);
                }).catch(e => {
                  // Unsubscription failed
                  console.log('Unsubscription failed!');
                });
              }
            }
          });
      });
  }

  async function subscribe(registration, isMobile) {
    await navigator.serviceWorker.getRegistration().then(async reg => {
      console.log(reg, 'Registration!');
      const subscription = await reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertVapidKey(CONSTANTS.publicVapidKey)
      });
      if (isMobile) {
        let result = await axios.put(`${CONSTANTS.API_URL}/api/v1/savePushSubscription`,{pushSubscriptionForMobile: subscription});
        console.log(result, 'Subscription Saved', subscription);
        if (result.data.result === 'success') {
          await setItem('subscribe', {isSubscribed: true});
          // await stopLoader();
        }
      } else {
        let result = await axios.put(`${CONSTANTS.API_URL}/api/v1/savePushSubscription`,{pushSubscriptionForDesktop: subscription});
        console.log(result, 'Subscription Saved', subscription);
        if (result.data.result === 'success') {
          await setItem('subscribe', {isSubscribed: true});
          // await stopLoader();
        }
      }
    });
  }

  function getBrowserVersion() {
    let detectIEregexp, isEdge;
    console.log("navigator.userAgent",navigator.userAgent);
    if (navigator.userAgent.indexOf('MSIE') !== -1) {
      detectIEregexp = /MSIE (\d+\.\d+);/; //test for MSIE x.x
    }
    else if (navigator.userAgent.indexOf('Edg') !== -1) {
      detectIEregexp = /Edg/;
      isEdge = true;
    }
    else {
      detectIEregexp = /Trident.*rv[ :]*(\d+\.\d+)/; //test for rv:x.x or rv x.x where Trident string exists
    } // if no "MSIE" string in userAgent
    console.log("detectIEregexp.test(navigator.userAgent)",detectIEregexp.test(navigator.userAgent));
    if (detectIEregexp.test(navigator.userAgent)){ //if some form of IE
      if (isEdge) {
        return 'Edge';
      }
      let ieversion=new Number(RegExp.$1); // capture x.x portion and store as a number
      if (ieversion>=11 && ieversion<12)
        return (ieversion);
      else if (ieversion>=9 && ieversion<10)
        return (ieversion);

    } else {
      return ("Other");
    }
  }
  async function toggleOnGoingCallWindow() {
    let TruecallerData = getItem('trueCallerData');
    let currentRowID = getItem('currentRowID');
    await setTrueCallerData(TruecallerData);
    await setCurrentInteraction(currentRowID);
    await setOngoingCallWindowOpen(!isOngoingCallWindowOpen);
  }

  function toggleLeftInfoPanel(e) {

    setShowLeftPanel(prev=> !prev);

  }

  /*
    Bug: T1692 - 9 - Mobile - The bottom panel for the ios is not visible
    Modification: Commented footer hide functionality for now
    By: Devang
    Date: 14/04/2024
  */
  function handleScroll(event){
    /*const currentScrollY = event.currentTarget.scrollTop;
    if (prevScrollY.current < currentScrollY && goingUp) {
      setGoingUp(false);
      setShowMobileFooter(false);

    }
    if (prevScrollY.current > currentScrollY && !goingUp) {
      setGoingUp(true);
      setShowMobileFooter(true);
    }

    prevScrollY.current = currentScrollY;*/

  }
  useEffect(()=>{
    if (window.screen.availWidth >= 481){
      setIsMobileView(false);
    }
    else {
      setIsMobileView(true);
    }
  },[window.screen.availWidth]);

    /*
     Modification: Added socketio
     By: Arun Singh
     Date: 18/04/2023
   */
   useEffect(async() => {
     socket.on('message', async(response) => {
       console.log(response,'socket responsessssssssssssssssssssssssss');
       if (response) {
         setAlertNotificationCount(prev => prev + 1);
       }
     });
     socket.on('task', async(response) => {
       console.log(response,'socket responsessssssssssssssssssssssssss');
       if (response) {
         setAlertNotificationCount(prev => prev + 1);
       }
     })
     socket.on('case', async(response) => {
       console.log(response,'socket responsessssssssssssssssssssssssss');
       if (response) {
         setAlertNotificationCount(prev => prev + 1);
       };
     })
   }, [socket]);

  return (

    <div style='display:flex'>
      <div >
        <Match path="/">
          {
            ({ path }) => {
              /*
                Modification: T1654 - Added condition for nonprotected routes
                By: Devang
                Date: 26/03/2024
              */
              // if (path !== '/' && path !== '/readEmails' && path !== '/set-new-password' && path !== '/set-password' && path !== '/parineetihonda' && path !== '/kotharihyundai' && !(path.startsWith('/caseDetail') && path.endsWith('/login')) && !(path.startsWith('/caseView') && customerInfo.isCustomerLogIn)){
              if (!unProtectedRoutes.includes(path) && !(path.startsWith('/caseDetail') && path.endsWith('/login')) && !(path.startsWith('/caseView') && customerInfo.isCustomerLogIn)){
                return (
                  <div>
                    <NavbarV2 />
                  </div>
                );
              }
            }
          }
        </Match>
      </div>
      {
        !isBrowserNotSupported  && (
          <div id="app">
            <link rel="manifest" href="/manifest.json" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link rel="stylesheet" href="/assets/js/toastr.css" />
            <link rel="stylesheet" href="/assets/dist/jsonview.bundle.css" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
            <link href="https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;500;600&display=swap" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
            {/*<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.97.7/css/materialize.min.css"/>*/}
            <script src="https://cdn.jsdelivr.net/gh/foobar404/wave.js/dist/bundle.iife.js" />
            <script src='https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.68/vfs_fonts.js' />
            <script src='https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.68/pdfmake.js' />
            <script src='/assets/js/toastr.min.js' />
            <script src="https://unpkg.com/gojs@2.2.14/release/go.js" />
            <script src="https://unpkg.com/gojs@2.2.14/extensions/BalloonLink.js" />
            <script src="https://polyfill.io/v3/polyfill.min.js?features=default" />
            <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyC2pHwk5BNWVswDVf6RZVEXFx7dvWsqAOA" defer />
            <script src='/assets/dist/jsonview.bundle.js' />
            <script src="/assets/js/zoop-sdk.min.js" />
            <Match path="/">
              {
                ({ path }) => {
                  /*
                    Modification: T1654 - Added condition for nonprotected routes
                    By: Devang
                    Date: 26/03/2024
                  */
                  // if (path !== '/' && path !== '/readEmails' && path !== '/set-new-password' && path !== '/set-password' && path !== '/parineetihonda' && path !== '/kotharihyundai' && !(path.startsWith('/caseDetail') && path.endsWith('/login')) && !(path.startsWith('/caseView') )){
                  if (!unProtectedRoutes.includes(path) && !(path.startsWith('/caseDetail') && path.endsWith('/login')) && !(path.startsWith('/caseView') )){
                    return (
                      <div style="display:flex">
                        {
                          isOffline && (
                            <NoInternet />
                          )
                        }

                        {/*
                          isSlowInternetSpeed && (
                            <SlowInternetSpeed />
                          )
                        */}
                      </div>
                    );
                  }
                }
              }
            </Match>
            <div>
              <ContextOne.Provider value={[state, dispatch]}>
                <SocketProvider>
                  <UsersProvider>
                    <Suspense fallback={<div>Loading...</div>}>
                      <Router>
                        <Login path="/:tenant?" isMobileView={isMobileView}/>
                        <WorkspaceV3 path="/workspace" toggleLeftInfoPanel={toggleLeftInfoPanel} showMobileFooter={showMobileFooter} handleScroll={handleScroll} />
                        <SetNewPassword path="/set-new-password/:token?" isMobileView={isMobileView}/>
                        <SetPasswordToVerify path="/set-password" isMobileView={isMobileView}/>
                        <Profile path="/profile" alertNotificationCount={alertNotificationCount}/>
                        <SiloAdministration path="/siloAdministration" alertNotificationCount={alertNotificationCount}/>
                        <Organization path="/organization" alertNotificationCount={alertNotificationCount}/>
                        <CaseDetailLogin path="/caseDetail/:id/login" alertNotificationCount={alertNotificationCount}/>
                        <ProjectTracker path="/projectTracker" alertNotificationCount={alertNotificationCount}/>
                        <Workspacedetails path="/workspacedetails/:type" isMobileView={isMobileView} showMobileFooter={showMobileFooter} setShowMobileFooter={setShowMobileFooter} handleScroll={handleScroll} toggleLeftInfoPanel={toggleLeftInfoPanel}
                          isOngoingCallWindowOpen={isOngoingCallWindowOpen} trueCallerData={trueCallerData} currentInteraction={currentInteraction} alertNotificationCount={alertNotificationCount}
                        />
                        <WorkspaceCaseDetailsV2 path="/workspaceCaseDetails" isMobileView={isMobileView} showMobileFooter={showMobileFooter} setShowMobileFooter={setShowMobileFooter} handleScroll={handleScroll} onGoingCallAction={(e)=> toggleOnGoingCallWindow(e)}
                          toggleLeftInfoPanel={toggleLeftInfoPanel} alertNotificationCount={alertNotificationCount}
                        />
                        <Search path="/search" alertNotificationCount={alertNotificationCount}/>
                        <CaseCommitment path="/caseCommitment" alertNotificationCount={alertNotificationCount}/>
                        <Esign path="/esign?action=esign-sucess" alertNotificationCount={alertNotificationCount}/>
                        <EsignatureInitiate path="/initiate/esign/:request_id" alertNotificationCount={alertNotificationCount}/>
                        <Checklist path="/checklist" alertNotificationCount={alertNotificationCount}/>
                        <ProcessMapping path="/processMapping" alertNotificationCount={alertNotificationCount}/>
                        <AclPermissions path="/aclPermissions" alertNotificationCount={alertNotificationCount}/>
                        <CaseView path="/caseView/:id" isMobileView={isMobileView} onGoingCallAction={(e)=> toggleOnGoingCallWindow(e)} isOngoingCallWindowOpen={isOngoingCallWindowOpen} trueCallerData={trueCallerData} alertNotificationCount={alertNotificationCount}/>
                        {
                          /*
                            Modification: Remove taskView imports from app.js
                            By: Manohar
                            Date: 21/06/2024
                          */
                        }
                        {/*<TaskView path="/taskView/:id" alertNotificationCount={alertNotificationCount}/>*/}
                        <ProcessView path="/processView/:id" isMobileView={isMobileView} alertNotificationCount={alertNotificationCount} updateUserInfo={() => updateUserInfo()} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        <StockDashboard2 path="/stockDashboard" alertNotificationCount={alertNotificationCount}/>
                        <OrderDashboard path="/orderDashboard" alertNotificationCount={alertNotificationCount}/>
                        <NotificationDashboard path="/notificationDashboard" alertNotificationCount={alertNotificationCount} setAlertNotificationCount={setAlertNotificationCount}/>
                        <Analytics path="/analytics" alertNotificationCount={alertNotificationCount}/>
                        <JobWorkspace path="/jobWorkspace" isMobileView={isMobileView} showMobileFooter={showMobileFooter} onGoingCallAction={(e)=> toggleOnGoingCallWindow(e)} handleScroll={handleScroll} alertNotificationCount={alertNotificationCount}/>
                        <SchedulerDashboard path="/schedulerDashboard" updateUserInfo={() => updateUserInfo()} alertNotificationCount={alertNotificationCount}/>
                        <SchedulerList path="/schedulerList/:jobHouseID?/:jobStatus?" updateUserInfo={() => updateUserInfo()} alertNotificationCount={alertNotificationCount}/>
                        <WorkspaceApprovalDetailsV3 path="/workspaceApprovalDetails" isMobileView={isMobileView} showMobileFooter={showMobileFooter} setShowMobileFooter={setShowMobileFooter} handleScroll={handleScroll} toggleLeftInfoPanel={toggleLeftInfoPanel}
                          isOngoingCallWindowOpen={isOngoingCallWindowOpen} trueCallerData={trueCallerData} currentInteraction={currentInteraction} alertNotificationCount={alertNotificationCount}
                        />
                        {/*
                          Bug: T1823 - BG_FN: Insurance List View: My Approval and Aprroval Search is not working
                          Modification: Commented my approval details page as it is same as workspaceApprovalDetails as discussed by rutuja
                          By: Devang
                          Date: 04/05/2024
                        */}
                        {/*<WorkspaceMyApprovalDetails path="/workspaceMyApprovalDetails" isMobileView={isMobileView} showMobileFooter={showMobileFooter} setShowMobileFooter={setShowMobileFooter} handleScroll={handleScroll} toggleLeftInfoPanel={toggleLeftInfoPanel}
                          isOngoingCallWindowOpen={isOngoingCallWindowOpen} trueCallerData={trueCallerData} currentInteraction={currentInteraction} updateUserInfo={() => updateUserInfo()} alertNotificationCount={alertNotificationCount}
                        />*/}
                        <FormViewer path="/formViewer" />
                        <WorkspaceCouponDetails path="/workspaceCouponDetails" />
                        <WorkspacePolicyCouponDetails path="/workspacePolicyCouponDetails" />
                        <CaseManagementV4 path="/caseManagement" />
                        <VehicleStock path='/vehicle' />
                        <Contact path="/contact" isMobileView={isMobileView} alertNotificationCount={alertNotificationCount} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        <TicketList path="/ticket/list" isMobileView={isMobileView} isTicketListUpdated={isTicketListUpdated} />
                        <TicketKanban path="/ticket/kanban" isMobileView={isMobileView} isTicketListUpdated={isTicketListUpdated} />
                        <CollaboratorChat path='/collaborator/:activeStage?/:activeSubCaseStatus?' isMobileView={isMobileView} toggleLeftInfoPanel={toggleLeftInfoPanel} handleScroll={handleScroll} setShowMobileFooter={setShowMobileFooter} alertNotificationCount={alertNotificationCount} isCollaboratorListSearchInput={isCollaboratorListSearchInput} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        <HomeV2 path="/homeV2" />
                        <SalesCRMV2 path="/salesCRMV2" />
                        <InsuranceRenewalV2 path="/insuranceRenewalV2" isMobileView={isMobileView} />
                        {/*
                          modified : Ashutosh G
                          modified : 19/03/2024
                          modification : added activeStage and activeSubCaseStatus optional parameters in /collaborator, /salesCRM/cases, /salesBackOffice/cases and /salesFollowups paths
                        */}
                        <SalesCRMCases path='/salesCRM/cases/:activeStage?/:activeSubCaseStatus?' alertNotificationCount={alertNotificationCount} isMobileView={isMobileView} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        <SalesBackOfficeCases path='/salesBackOffice/cases/:activeStage?/:activeSubCaseStatus?' isMobileView={isMobileView} alertNotificationCount={alertNotificationCount} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        {/*
                          Bug: T1814 - BG_FN: Insurance - Insurance Renewal Workspace page - Tasks and Cases - Missing Integration for New List View
                          Modification: Commented cases and tasks page of insurance module
                          By: Devang
                          Date: 31/05/2024
                        */}
                        {/*<CRMStages path='/CRM/:type/:stage/:filter' isMobileView={isMobileView} showMobileFooter={showMobileFooter} onGoingCallAction={(e)=> toggleOnGoingCallWindow(e)} handleScroll={handleScroll} />
                        <InsuranceCRMCases path='/CRM/insuranceRenewal/cases' />*/}
                        <SalesFollowups path='/salesFollowups/:activeStage?/:activeSubCaseStatus?' alertNotificationCount={alertNotificationCount} isMobileView={isMobileView} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        <CollaboratorAI path="/collaboratorAI" isMobileView={isMobileView} handleScroll={handleScroll} setShowMobileFooter={setShowMobileFooter} alertNotificationCount={alertNotificationCount} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        <SalesHomeV2 path="/salesHomeV2" isMobileView={isMobileView} handleScroll={handleScroll} setShowMobileFooter={setShowMobileFooter} alertNotificationCount={alertNotificationCount} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        {/*
                          BY: Prasannadatta kawadkar
                          ON: 01 March 2024
                          Modification: added new page for new sales dashboard
                        */}
                        <SalesDashboard path='/salesDashboard' isMobileView={isMobileView} alertNotificationCount={alertNotificationCount} />
                        <SalesFollowups path='/salesFollowups' />
                        <HomeV3 path='/home' isMobileView={isMobileView} alertNotificationCount={alertNotificationCount} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        <MyTasks path='/myTasks' isMobileView={isMobileView} alertNotificationCount={alertNotificationCount} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        <DigitalAllocation path='/digitalAllocation' isMobileView={isMobileView} handleScroll={handleScroll} showMobileFooter={showMobileFooter} setShowMobileFooter={setShowMobileFooter} alertNotificationCount={alertNotificationCount} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        {/*
                          BY: Prasannadatta kawadkar
                          ON: 03 April 2024
                          Modification: added entityFormMapping and ticketing Page
                        */}
                        <EntityFormMapping path="/entityFormMapping" />
                        <Ticketing isMobileView={isMobileView} path='/ticketing/:requestStage' alertNotificationCount={alertNotificationCount} />
                        <TicketDetails isMobileView={isMobileView} path='/ticketDetails/:requestID' alertNotificationCount={alertNotificationCount} />
                        <DataStatusDashboard path="/dataStatusDashboard" isMobileView={isMobileView} handleScroll={handleScroll} setShowMobileFooter={setShowMobileFooter} alertNotificationCount={alertNotificationCount} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        <CaseStatus path="/caseStatus/:status?" isMobileView={isMobileView} handleScroll={handleScroll} setShowMobileFooter={setShowMobileFooter} alertNotificationCount={alertNotificationCount} setIsCollaboratorListSearchInput={setIsCollaboratorListSearchInput}/>
                        {/*
                          modified : Ashutosh G
                          modified : 11/06/2024
                          modification : reports route added
                        */}
                        {/*REPORT_COMMENTED_BY_DJ <Reports path="/reports/:type?" />*/}
                      </Router>
                    </Suspense>
                  </UsersProvider>
                </SocketProvider>
              </ContextOne.Provider>
            </div>
          </div>
        )}
      {
        isBrowserNotSupported && (
          <div>
            <BrowserNotSupported path="/browserNotSupported" />
          </div>
        )
      }
      <div>
        <Match path="/">
          {
            ({ path }) => {
              /*
                Modification: T1654 - Added condition for nonprotected routes
                By: Devang
                Date: 26/03/2024
              */
              // if (path !== '/' && path !== '/readEmails' && path !== '/set-new-password' && path !== '/set-password' && path !== '/parineetihonda' && path !== '/kotharihyundai' && path !== '/loginPage' && !(path.startsWith('/caseDetail') && path.endsWith('/login')) && !(path.startsWith('/caseView') && customerInfo.isCustomerLogIn)){
              if (!unProtectedRoutes.includes(path) && !(path.startsWith('/caseDetail') && path.endsWith('/login')) && !(path.startsWith('/caseView') && customerInfo.isCustomerLogIn)){
                return (
                  <div>
                    <RightPanel
                      isMobileView={isMobileView}
                      showMobileFooter={showMobileFooter}
                      isOngoingCallWindowOpen={isOngoingCallWindowOpen}
                      trueCallerData={trueCallerData}
                      currentInteraction={currentInteraction}
                      setIsTicketListUpdated={setIsTicketListUpdated}
                    />
                    {/*
                      modified : Ashutosh G
                      modified : 08/03/2024
                      modification : Chatbot included in APP.js to show on all pages
                    */}
                    {/*
                      Modification: T1691 - Commented chat icon from collaborator page
                      By: Devang
                      Date: 12/04/2024
                    */}
                    {
                      (!["/collaboratorAI"].includes(path) && !path.includes("/collaborator")) && (
                        <Chatbot setShowMobileFooter={setShowMobileFooter}/>
                      )
                    }
                  </div>
                );
              }
            }
          }
        </Match>
      </div>
    </div>

  );
};
export default App;
