/*
   modified by Komal
   modified on 15/04/2023
   modification: DraggableCalculator component
*/
import interact from 'interactjs';
import { useEffect } from 'preact/hooks';
import { ReactCalculator } from "simple-react-calculator";


const DraggableCalculator = (props) => {
  console.log(props, 'propsprops');
  useEffect(()=> {
    draggableButton();
  },[]);

  function draggableButton() {
    let element = document.getElementById(props.id);
    let x = 0; let y = 0;

    interact(element)
      .draggable({
        modifiers: [
          interact.modifiers.snap({
            targets: [
              interact.snappers.grid({ x: 30, y: 30 })
            ],
            range: Infinity,
            relativePoints: [ { x: 0, y: 0 } ]
          }),
          interact.modifiers.restrict({
            restriction: element.parentNode,
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
            endOnly: true
          })
        ],
        inertia: true
      })
      .on('dragmove', (event) => {
        x += event.dx;
        y += event.dy;
        console.log(x,'===', y);
        event.target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
      })
      .on('tap', (event) => {
        // props.clickEvent && props.clickEvent();
        event.preventDefault();
      });
  }

  return (
    <div
      id={props.id}
      class={`${props.classes ? props.classes : 'drag-drop-floating-calculator'} ${props.ripple ? 'rippleAnimation': ''}`}
      data-toggle="tooltip"
      data-original-title="Create"
    >
      <div class='orgChartModal'>
        <div id="modelCaseModal" style="display:block;" class="calculator-modal">
          <div class="calculator-modal-content">
            <div>
              <div class={`modal-header`}>
                <span class='fs-12'>
                  <div class="display-flex">
                    <ul class='breadrcrumb_arrows_inverted m-r-8'>
                      <li>Calculator</li>
                    </ul>
                  </div>
                </span>
                <span class="modal-close" onClick={(e) => props.clickEvent(e)}>&times;</span>
              </div>
              <div class="calculator-modal-body">
                <div class="row">
                  <ReactCalculator />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span id="dragIcon" onClick={(e) => e.stopPropagation()} class={`cursor-move pos-absolute`} style="left: -18px;top: -8px;" />
    </div>
  );
};
export default DraggableCalculator;
