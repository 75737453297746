import { h, Fragment } from 'preact';
import { NewPopupModal, NewPopupModalBody } from '../../components/newPopupModal';
import CoreEntityDynamicFormNoModal from '../../components/coreEntityDynamicFormNoModal';

const CoreEntityDynamicForm = (props) => {
  // Main form props data to get from processMapping
  /*
    - formOperation
    - formEntityName
    - formEntityObject
    - setFormEntityObject
    - formDynamicProps
  */
  let {
    operation,
    toggleFormPopover,
    backgroundColor,
    dynamicProps,
    entityObject,
    isListDataReLoad,
    masterTask,
    isMobileView
  } = props;

  return (
    <Fragment>
      <NewPopupModal operation={operation} isMobileView={isMobileView} classes="formModal" modalWidth={"90%"} modalDisplay={('show-formPopover overflow-hidden')} onClose={(e) => toggleFormPopover(e)} backgroundColor={backgroundColor ? backgroundColor: "#f7f7f7"}>
        <CoreEntityDynamicFormNoModal {...props} />
      </NewPopupModal>
    </Fragment>
  );
};

export default CoreEntityDynamicForm;
