/*
  COMP: T311: BG_FN: Dispute Case Status is not getting updated immediately
  COMP: T326: BG_PROD: Reopening a lost case doesn't change case status from LOST to DUE
  T1955: Also check for Case Status is not updated from Due to Renewed immediately
  T1955: Case status is not updated from Due to Lost immediately (need to refresh)
  Modification: Update card details on prop change
  By: Yashvi
  On: 22nd July 2024
*/
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Ribbon from '../../components/ribbon';
import SkeletonCard from '../../components/skeletonCard';

const SemiInfoDetailCard = (props) => {
  const {
    identifier,
    title,
    masterCardClasses,
    cardBgColor,
    cardHeight,
    cardPadding,
    masterCardStyle,
    action,
    masterClasses,
    cardTextTopClasses,
    cardHeaderClasses,
    iconMasterClasses,
    isCardIconFaded,
    cardIcon,
    masterCardMobileClasses,
    cardSubHeaderMobileClasses,
    cardSubHeaderClasses
  } = props;

  const [cardTextTop, setCardTextTop] = useState(props.cardTextTop);
  const [cardHeader, setCardHeader] = useState(props.cardHeader);
  const [cardSubHeader, setCardSubHeader] = useState(props.cardSubHeader);
  const [cardPreSubHeader, setCardPreSubHeader] = useState(props.cardPreSubHeader);
  const [cardPreSubHeaderBold, setCardPreSubHeaderBold] = useState(props.cardPreSubHeaderBold);
  const [cardSubHeaderBold, setCardSubHeaderBold] = useState(props.cardSubHeaderBold);
  const [cardSubHeaderTrimmed, setCardSubHeaderTrimmed] = useState(props.cardSubHeaderTrimmed);
  const [cardSubHeaderTrimmedBold, setCardSubHeaderTrimmedBold] = useState(props.cardSubHeaderTrimmedBold);
  const [cardNextSubHeader, setCardNextSubHeader] = useState(props.cardNextSubHeader);
  const [cardNextSubHeaderBold, setCardNextSubHeaderBold] = useState(props.cardNextSubHeaderBold);
  const [cardText1, setCardText1] = useState(props.cardText1);
  const [cardText2, setCardText2] = useState(props.cardText2);
  const [cardText3, setCardText3] = useState(props.cardText3);
  const [cardTextOuter, setCardTextOuter] = useState(props.cardTextOuter);
  const [ribbons, setRibbons] = useState(props.ribbons);
  const [tagName, setTagName] = useState(props.tagName);

  useEffect(() => {
    setCardTextTop(props.cardTextTop);
  }, [props.cardTextTop]);

  useEffect(() => {
    setCardHeader(props.cardHeader);
  }, [props.cardHeader]);

  useEffect(() => {
    setCardSubHeader(props.cardSubHeader);
  }, [props.cardSubHeader]);

  useEffect(() => {
    setCardPreSubHeader(props.cardPreSubHeader);
  }, [props.cardPreSubHeader]);

  useEffect(() => {
    setCardPreSubHeaderBold(props.cardPreSubHeaderBold);
  }, [props.cardPreSubHeaderBold]);

  useEffect(() => {
    setCardSubHeaderBold(props.cardSubHeaderBold);
  }, [props.cardSubHeaderBold]);

  useEffect(() => {
    setCardSubHeaderTrimmed(props.cardSubHeaderTrimmed);
  }, [props.cardSubHeaderTrimmed]);

  useEffect(() => {
    setCardSubHeaderTrimmedBold(props.cardSubHeaderTrimmedBold);
  }, [props.cardSubHeaderTrimmedBold]);

  useEffect(() => {
    setCardNextSubHeader(props.cardNextSubHeader);
  }, [props.cardNextSubHeader]);

  useEffect(() => {
    setCardNextSubHeaderBold(props.cardNextSubHeaderBold);
  }, [props.cardNextSubHeaderBold]);

  useEffect(() => {
    setCardText1(props.cardText1);
  }, [props.cardText1]);

  useEffect(() => {
    setCardText2(props.cardText2);
  }, [props.cardText2]);

  useEffect(() => {
    setCardText3(props.cardText3);
  }, [props.cardText3]);

  useEffect(() => {
    setCardTextOuter(props.cardTextOuter);
  }, [props.cardTextOuter]);

  useEffect(() => {
    setRibbons(props.ribbons);
  }, [props.ribbons]);

  useEffect(() => {
    setTagName(props.tagName);
  }, [props.tagName]);

  return (
    <div
      id={identifier ? identifier : `${title}`}
      class={`newWorkSpaceCard pos-relative semi-info-detail-card overflow-hidden cursor-pointer ${masterCardMobileClasses || 'mobile-p-t-30 mobile-p-l-2'} ${masterCardClasses || ''}`}
      style={`background:${cardBgColor};height:${cardHeight}; padding: ${cardPadding || '16px'}; ${masterCardStyle || ''}`}
      onclick={(e) => action && action(e)}
      title={title || ''}
    >
      <div class={masterClasses || "p-t-0 p-b-0 display-flex justify-between flex-direction-column h-full first-letter-capital m-l-8"}>
        {cardHeader && (
          <div class={!cardTextTop ? "display-flex" : ""} style="">
            {cardTextTop && (
              <p class={`fs-12 f-w-400 ${cardTextTopClasses ? cardTextTopClasses : " "}`} style="align-self: baseline;">
                <span>{cardTextTop}</span>
              </p>
            )}
            <h1 class={cardHeaderClasses || `fs-15 mobile-fs-11 f-w-400 text-black`} style="margin:0; align-self: baseline;letter-spacing: 0.08px;">
              {cardHeader && cardHeader !== "" && cardHeader !== '-' ? cardHeader : 'N/A'}
            </h1>
            {tagName && (
              <div class="display-flex" style="width: 54%;justify-content:end;align-self: self-end;">
                <span class="fs-10 f-w-500 m-l-10" style="border-radius:20px;color:#fff;padding:5px 10px;background:#154042;align-self: center;">
                  {tagName}
                </span>
              </div>
            )}
          </div>
        )}
        {ribbons && ribbons.length > 0 && ribbons.map((ribbon) => (
          <Ribbon
            ribbonText={ribbon.text}
            ribbonColor={ribbon.color}
            type={ribbon.type}
            variant={ribbon.variant}
            isTextNotVisible={ribbon.isTextNotVisible}
            ribbonTextColor={ribbon.ribbonTextColor}
            title={ribbon.title}
          />
        ))}
        {cardSubHeader && (
          <div class="display-flex" style="width: 100%;justify-content: space-between; align-self: self-end;">
            <p class={`${cardSubHeaderClasses ? cardSubHeaderClasses : " "} fs-13 mobile-fs-9 f-w-400`}>
              {cardSubHeader && cardSubHeader !== "" && cardHeader !== '-' ? cardSubHeader : 'N/A'}
            </p>
          </div>
        )}
        {cardText1 && (
          <p class="fs-13 f-w-400" style="align-self: baseline;">
            <span>{cardText1 !== "" && cardText1 !== '- | -' && cardText1 !== '-' ? cardText1 : 'N/A'}</span>
          </p>
        )}
        {cardText2 && (
          <p class="fs-11 f-w-400" style="align-self: baseline;">
            <span>{cardText2 !== " " && cardText2 !== "" && cardText2 !== '- | -' ? cardText2 : 'N/A'}</span>
          </p>
        )}
        {cardText3 && (
          <p class="fs-13 f-w-400" style="align-self: baseline;">
            <span>{cardText3 !== "" && cardText3 !== '- | -' ? cardText3 : 'N/A'}</span>
          </p>
        )}
      </div>
      <span class={`${iconMasterClasses || 'pos-absolute right-0 bottom-0 op-0-6'} ${isCardIconFaded ? 'op-0-6' : ''}`}>
        {cardIcon}
      </span>
      {cardTextOuter && (
        <div class="">
          <p class="fs-11 f-w-300" style="align-self: baseline;">
            <span>{cardTextOuter}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default SemiInfoDetailCard;
